import 'maui/dist/styles/maui.css';

import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { Loader, theme, Sidebar as MauiSidebar } from 'maui';
import { Provider, useDispatch } from 'react-redux'
import { BrowserRouter, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ISmbPlan, IUser } from 'findem-types';
import { init as initFullStory } from '@fullstory/browser';
import { isSmbAccountAdmin, isSmbAccountOwner, isSmbApp } from 'findem-helpers';

// APIs
import { useGetCurrentUserQuery, useGetPlatformRolesQuery, useGetPlatformUsersQuery } from './services/users';
import { useGetAccountPlanQuery, useGetPlansQuery } from './services/plans';
import { useGetAccountUsageQuery } from './services/billing';

// Types
import { RootState } from './store';

// Utils
import { store } from './store'
import { appStateActions } from './reducers/appState';
import { smbStateActions } from './reducers/smbState';

// Styled + Components
import AppStyles, { MainWrapper } from './App.styled';
import LoggedOutSplash from './components/LoggedOutSplash';
import AppRoutes from './components/Routes';
import Sidebar from './components/Sidebar';

const App = () => {
  const dispatch = useDispatch();

  //--------------------------------------------------------------------------------
  // Store
  //--------------------------------------------------------------------------------

  const storeUser: IUser = useSelector(
    (state: RootState) => state.appState.currentUser
  );

  const sidebarCollapsed: boolean = useSelector(
    (state: RootState) => state.appState.sidebarCollapsed
  );

  //--------------------------------------------------------------------------------
  // APIs
  //--------------------------------------------------------------------------------

  const {
    data: currentUser,
    isLoading: isCurrentUserLoading,
  } = useGetCurrentUserQuery();

  const {
    data: platformUser,
    isLoading: isPlatformUserLoading,
  } = useGetPlatformUsersQuery({
    accountId: currentUser?.accountId ?? '',
    resource_id: currentUser?.uid ?? ''
  }, {
    skip: !currentUser || !isSmbApp()
  });

  const {
    data: platformRoles,
    isLoading: isLoadingPlatformRoles
  } = useGetPlatformRolesQuery(undefined, {
    skip: !isSmbApp()
  });

  const {
    data: plans
  } = useGetPlansQuery(null , {
    skip: !currentUser || !isSmbApp()
  });

  const { data: accountPlan, isLoading: accountPlanLoading } = useGetAccountPlanQuery({
    accountId: currentUser?.accountId!
  }, {
    skip: !currentUser || !isSmbApp()
  })

  const { data: accountUsage } = useGetAccountUsageQuery({
    accountId: currentUser?.accountId!
  }, {
    skip: !currentUser || !isSmbApp()
  })

  //--------------------------------------------------------------------------------
  // Effects
  //--------------------------------------------------------------------------------

  useEffect(() => {
    if (currentUser) {
      dispatch(appStateActions.setCurrentUser(currentUser));
    }
  }, [currentUser]);

  useEffect(() => {
    const user = platformUser?.find((user) => user.resource_id === currentUser?.uid);

    if (user && platformRoles) {
      if (isSmbAccountAdmin({ userRoles: user.platform_roles, allRoles: platformRoles })) {
        dispatch(smbStateActions.setIsAccountAdmin(true));
      }
      if (isSmbAccountOwner({ userRoles: user.platform_roles, allRoles: platformRoles })) {
        dispatch(smbStateActions.setIsAccountOwner(true));
      }
    }
  }, [platformUser, currentUser]);

  useEffect(() => {
    if(accountPlan && plans) {
      const currentPlan = plans.find((plan: ISmbPlan)  => plan.ref === accountPlan?.[0]?.plan_id)

      dispatch(smbStateActions.setAccountSubscription(accountPlan))

      if(currentPlan) {
        dispatch(smbStateActions.setAccountPlan(currentPlan))
      }
    }
  }, [accountPlan, plans])

  useEffect(() => {
    if(accountUsage) {
      dispatch(smbStateActions.setAccountUsage(accountUsage))
    }
  }, [accountUsage]);

  if (isCurrentUserLoading) {
    return (
      <Loader $fullPage />
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <AppStyles />
      { !!currentUser && !!storeUser.uid ? (
          <BrowserRouter>
            <Sidebar />
            <MauiSidebar.MainWrapperWithSidebar
              $collapsed={sidebarCollapsed}
              id='main-wrapper'
            >
              <AppRoutes />
            </MauiSidebar.MainWrapperWithSidebar>
          </BrowserRouter>
        ) : (
          <LoggedOutSplash />
        )
      }
    </ThemeProvider>
  )
}
const LiftedApp = () => {
  initFullStory({ orgId: 'VB5PS', devMode: process.env?.NODE_ENV === 'development' });

  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
}

export default LiftedApp;
